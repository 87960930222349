.MuiPaper-root {
  width: 90%;
  margin: auto;
  border-radius: var(--borderRadius-xg) !important;
  border: 1px solid var(--black-300);
}

.MuiToolbar-root {
  border-radius: var(--borderRadius-xg);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-width: 200px !important;
}

.css-4g8ult-MuiContainer-root {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 10px;
}

.paragraph {
  font-weight: 700;
  line-height: 32px; /* 200% */
}

.css-10hburv-MuiTypography-root {
  font-family: 'Nunito Sans', sans-serif !important;
}
