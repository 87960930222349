.MuiPaper-root {
  width: 90%;
  margin: auto;
  border-radius: var(--borderRadius-xg) !important;
  border: 1px solid var(--black-300);
}

.MuiToolbar-root {
  border-radius: var(--borderRadius-xg);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-width: 350px !important;
}

.css-4g8ult-MuiContainer-root {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 10px;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-top: 0 !important;
  border-radius: 4px !important;
  border-right: 1px solid var(--black-300) !important;
  border-bottom: 1px solid var(--black-300) !important;
  border-left: 1px solid var(--black-300) !important;
} */
