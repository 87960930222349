@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-page form {
  margin: auto;
  padding: 36px 0;
}

.login-img {
  width: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 480px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.login-img {
  width: 100%;
}

.login-field {
  color: #505050;
  height: 44px;
  font-family: Lato;
  font-size: 1rem;
  background-color: rgba(195, 197, 199, 0.2);
  border: 2px solid rgba(195, 197, 199, 0);
  border-radius: 5px;
  padding-left: 15px;
  width: 100%;
  box-sizing: border-box;
}

.login-field:focus {
  outline: none;
  border: 2px solid #4ea4b7;
  background-color: rgba(134, 205, 220, 0.12);
}

.login-field:focus::placeholder {
  color: #4ea4b7;
}

.login-button {
  height: 44px;
  font-family: Lato;
  background-color: var(--primary-500);
  color: var(--black-0);
  font-weight: 900;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 12px;
}

.login-container h1 {
  font-family: Lato;
  font-size: 1.7rem;
  color: #505050;
  text-align: center;
  margin: 0;
}

.login-container a {
  font-family: Lato;
  color: #86cddc;
}

.login-button {
  font-family: Lato;
  font-weight: 900;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.smallText a {
  color: #ea5261;
  font-weight: 700;
}

.app-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.android {
  width: 102px;
  height: 32px;
}
.apple {
  width: 102px;
  height: 32px;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.signup-img {
  width: 100%;
}

.signup-button {
  height: 44px;
  font-family: Lato;
  background-color: #ffffff;
  color: var(--primary-500);
  font-weight: 900;
  border: 1px solid var(--primary-500);
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 02px;
}
