.leaflet-container {
  height: 80vh;
  width: 100%;
  z-index: 2;
  border-radius: 8px;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-popup-content-wrapper {
  position: relative;
}
