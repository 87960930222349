.route-event-chip {
  position: absolute;
  top: -15px;
  right: 8px; /* ajuste este valor conforme necessário para controlar a parte fora do card */
  z-index: 1; /* Colocar o chip sobre o conteúdo do card */
}

.css-1fd1kmo-MuiChip-root {
  border-radius: var(--borderRadius-p) !important;
  border: 2px solid var(--black-50);
  background: var(--black-50);
}

.cards-avatar .css-1fd1kmo-MuiChip-root {
  border-radius: 6px;
  border: 2px solid var(--black-400);
  background: var(--black-400);
}

.tool-bar .css-1ps6pg7-MuiPaper-root {
  width: 150px;
}

.css-864ckt-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus {
  background-color: var(--white-100) !important;
  border: none;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: 0;
  max-height: 550px;
}
