.css-1wd7awp-MuiInputBase-root-MuiInput-root:before {
  border: 0 !important;
}
.css-1wd7awp-MuiInputBase-root-MuiInput-root:after {
  border: 0 !important;
}

.week-date-container {
  max-width: 550px;
  margin-bottom: 15px;
}

.week-date-container .css-11a8txn-MuiStack-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.week-date-container .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
  min-height: 200px !important;
}

.week-date-container .css-11a8txn-MuiStack-root > :not(style) ~ :not(style) {
  margin-top: 0;
}

.week-date-container .css-1u8a0n-MuiButtonBase-root-MuiButton-root {
  display: none;
}

.week-date-container .css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  display: none;
}

.week-date-container .css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  display: none;
}

.selected-day-container {
  border: 1px solid var(--black-300);
  border-radius: var(--borderRadius-m);
  box-shadow: var(--shadow-6);
  padding: 5px;
  height: 265px;
  overflow-y: hidden;
}

.selected-day {
  overflow-y: scroll;
  max-height: 228px;
}

.single-day-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
}
