/* fonts  */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,600;6..12,700&display=swap');

:root {
  /* user  admin siri */
  --primary-0: #d6eef3;
  --primary-25: #bce3eb;
  --primary-50: #a1d9e4;
  --primary-75: #87cedc;
  --primary-100: #60bed0;
  --primary-200: #45b3c9;
  --primary-300: #35a1b7;
  --primary-400: #2e8a9c;
  --primary-500: #226775;
  --primary-600: #1a505b;
  --primary-700: #133940;
  --primary-800: #0b2226;
  --primary-900: #071619;
  /* user catador  */
  --red-0: #f9cbd0;
  --red-25: #f5adb4;
  --red-50: #f18f98;
  --red-100: #ee707d;
  --red-200: #ea5261;
  --red-300: #e42537;
  --red-400: #ce192b;
  --red-500: #b01525;
  --red-600: #91121e;
  --red-700: #730e18;
  --red-800: #550a12;
  --red-900: #36070b;
  /* user empresa */
  --yellow-0: #ffeeb7;
  --yellow-25: #ffe595;
  --yellow-50: #ffd962;
  --yellow-100: #ffd140;
  --yellow-200: #ffc91e;
  --yellow-300: #fbbe00;
  --yellow-400: #d9a400;
  --yellow-500: #b78b00;
  --yellow-600: #957100;
  --yellow-700: #735700;
  --yellow-800: #513d00;
  --yellow-900: #2f2400;
  /* orange */
  --orange-400: #ff6c38;
  --orange-50: #fde9e6;
  /* black */
  --black-0: #ffffff;
  --black-50: #f4f4f4;
  --black-100: #e8e8e8;
  --black-300: #bbbbbb;
  --black-400: #a4a4a4;
  --black-800: #464646;
  /* semantical colors */
  --red: #ff3129;
  --green: #17b51c;
  /* background */
  --bgColor-1: #f0f9fb;
  --bgColor-2: #fceaeb;
  --bgColor-3: #fffaea;

  /* fonts  */
  font-family: 'Nunito Sans', sans-serif;

  /* rest of the vars */
  --transition: 0.3s ease-in-out all;
  --letterSpacing: 1px;

  /* border radius */
  --borderRadius-p: 4px; /* elementos pequenos */
  --borderRadius-m: 8px; /* elementos médios */
  --borderRadius-g: 12px; /* elementos grandes */
  --borderRadius-xg: 16px; /* elementos muito grandes */

  /* box shadow*/
  --shadow-1: 1px 1px 1px 0px rgba(0, 0, 0, 0.1); /*Para cards e elementos próximos à base, (Tabelas e planilhas)*/
  --shadow-2: 2px 2px 2px 0px rgba(0, 0, 0, 0.1); /*Para botões acima de cards ou outros elementos.*/
  --shadow-3: 4px 4px 4px 0px rgba(0, 0, 0, 0.1); /*Para menus de rodapé*/
  --shadow-4: 6px 6px 6px 0px rgba(0, 0, 0, 0.1); /*Usado em Floatting buttons*/
  --shadow-5: 8px 8px 8px 0px rgba(0, 0, 0, 0.1); /*Usado para menus de topo e laterais*/
  --shadow-6: 12px 12px 12px 0px rgba(0, 0, 0, 0.1); /*Usado para estados do Floatting button*/
  --shadow-7: 16px 16px 16px 0px rgba(0, 0, 0, 0.1); /*Usado nos modais*/
  --shadow-8: 24px 24px 24px 0px rgba(0, 0, 0, 0.1); /*Usado para pickers*/
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--black-0);
  max-width: 100vw;
  overflow-x: hidden;
}

/* 
  titles
    h1 - Usado para títulos com grande destaque
    h2 - Usado para títulos com médio/grande destaque
    h3 - Usado para títulos com médio/pequeno destaque
    h4 - Usado para títulos com pequeno destaque
    h5 - Usado para títulos com leve destaque
*/
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  color: var(--black-800);
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  text-transform: capitalize;
}
h1 {
  font-size: 34px;
  /* line-height: 48px; */
}
h2 {
  font-size: 24px;
  /* line-height: 32px; */
}
h3 {
  font-size: 20px;
  /* line-height: 32px; */
}
h4 {
  font-size: 16px;
  /* line-height: 24px; */
}
h5 {
  font-size: 14px;
  /* line-height: 24px; */
}

/* 
  paragraphs
    bigText - Usado para criação de massas de texto corrido ao longo das telas da interface. Esse é o parágrafo comum.
    normalText - Usado para criação de massas de texto corrido ao longo das telas da interface. Esse é o parágrafo menor.
    smallText - Usado principalmente para legendas e elementos de menor importância.
    miniText - Usado para textos extremamente pequenos da interface. Utilize para os elementos menos importantes.
*/
p {
  /* margin-top: 0;
  margin-bottom: 1.5rem;
  max-width: 40em; */
  color: var(--balck-800);
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
}
.bigText {
  font-size: 16px;
  line-height: 32px;
}
.normalText {
  font-size: 14px;
  line-height: 32px;
}
.smallText {
  font-size: 12px;
  line-height: 24px;
}
.miniText {
  font-size: 10px;
  line-height: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* buttons */

.btn {
  cursor: pointer;
  color: var(--black-0);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius-p);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transtion);
  text-transform: capitalize;
  display: inline-block;
}

.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

/* Scrollbar */
* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
Button {
  text-transform: none;
}
